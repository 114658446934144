body {
  margin: 0;
  font-family: ArtifaktElement, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Qualtrics */
.QSISlider.SI_e8ndiNLKuBxxRyu_SliderContainer {
  top: auto !important;
  bottom: 360px;
}

#slider-control-SI_e8ndiNLKuBxxRyu:focus {
  outline: none;
}
