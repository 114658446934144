@import-normalize;

/*
 * The following ArtifaktLegend snippet is obtained from the official web content repo. More fonts of 
 * different weights can be added as need arises.
 *
 * https://git.autodesk.com/secure-web-content/pharmacopeia/blob/master/assets/fonts/ArtifaktLegend/all.css
 */

@font-face {
  font-family: "ArtifaktLegend";
  src: local("Artifakt Legend Extra Bold"), local("ArtifaktLegend-ExtraBold"),
    url("https://swc.autodesk.com/pharmacopeia/fonts/ArtifaktLegend/v1.0/WOFF2/Artifakt%20Legend%20Extra%20Bold.woff2")
      format("woff2"),
    local("Artifakt Legend Extra Bold"), local("ArtifaktLegend-ExtraBold"),
    url("https://swc.autodesk.com/pharmacopeia/fonts/ArtifaktLegend/v1.0/WOFF/Artifakt%20Legend%20Extra%20Bold.woff")
      format("woff"),
    local("Artifakt Legend Extra Bold"), local("ArtifaktLegend-ExtraBold"),
    url("https://swc.autodesk.com/pharmacopeia/fonts/ArtifaktLegend/v1.0/TTF/Artifakt%20Legend%20Extra%20Bold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  font-family: ArtifaktElement, Arial, sans-serif;
  line-height: 1.5;
}

/**
* Image
*/
.fss-img-responsive {
  max-width: 100%;
}

/**
* Align
*/
.fss-align-left {
  text-align: left;
}

.fss-align-right {
  text-align: right;
}

.fss-align-center {
  text-align: center;
}

/**
* Grid
*/
.fss-grid {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.fss-grid-row {
  display: flex;
  flex: 1;
}

.fss-grid-container {
  display: flex;
  flex: 1;
  max-width: 1024px;
  margin: 0 auto;
  gap: 40px;
}

.fss-grid-col-1 {
  flex: 1;
}

.fss-grid-col-2 {
  flex: 2;
}

.fss-grid-col-3 {
  flex: 3;
}

.fss-grid-col-4 {
  flex: 4;
}

.fss-grid-col-5 {
  flex: 5;
}

.fss-grid-col-6 {
  flex: 6;
}

.fss-grid-col-7 {
  flex: 7;
}

.fss-grid-col-8 {
  flex: 8;
}

.fss-grid-col-9 {
  flex: 9;
}

.fss-grid-col-10 {
  flex: 10;
}

.fss-grid-col-11 {
  flex: 11;
}

.fss-grid-col-12 {
  flex: 12;
}
